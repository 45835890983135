import React from 'react'

export default function Card({ title, description, logo, image }) {
  return (
    <div className="_card">
      <div className="header-section">
        <img src={logo} alt="" className="logo" />
        <div className="header">
          <h5>{title}</h5>
          <p>{description}</p>
        </div>
      </div>
      <div className="body-section">
        <img src={image} alt="" className="image"/>
      </div>
    </div>
  )
}

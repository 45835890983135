import React from 'react';
import Layout from '../components/Layout';
import MoreFeatures from '../components/MoreFeatures';
import FeaturesSlider from '../components/FeaturesSlider';
import LegalSpotlight from '../components/LegalSpotlight';
import TestimonialSlider from '../components/TestimonialSlider';
import Quote from '../components/Quote';

import allocateScreenshot from '../assets/images/features-allocate.png';
import allocateLogo from '../assets/images/allocate.svg';
import blueprintScreenshot from '../assets/images/features-blueprint.png';
import blueprintLogo from '../assets/images/blueprint.svg';
import driveScreenshot from '../assets/images/features-drive.png';
import driveLogo from '../assets/images/drive.svg';
import playbookScreenshot from '../assets/images/features-playbook.png';
import playbookLogo from '../assets/images/playbook.svg';
import loop2 from '../assets/videos/FIBONACCI_SCENE3_SHOT2_LOOP.mp4';
import loop4 from '../assets/videos/FIBONACCI_SCENE3_SHOT4_LOOP.mp4';
import spectre from '../assets/videos/spectre.mp4';
import davidKerr from '../assets/images/david-kerr.jpg';
import twoBirdsLogo from '../assets/images/bird&bird--full--cyan.jpg';
import serverLogo from '../assets/images/server.svg';
import dataLogo from '../assets/images/data.svg';
import encryptionLogo from '../assets/images/encryption.svg';
import authenticationLogo from '../assets/images/2fa.svg';
import Integrate from '../components/features/Integrate';

const FeaturesPage = ({ path }) => (
  <Layout
    pageName="features"
    path={path}
    title="Features - Fibonacci"
    image="/metaimages/features.jpg"
    description="Drive work forward, without stress and bottlenecks."
  >
    <div className="banner">
      <div className="chip">FEATURES</div>
      <h1>
        Supercharged legal
      </h1>
      <h1>
        project management
      </h1>
      <p>Drive work forward, without the stress and bottlenecks.</p>
    </div>
    <div className="flexible-container -loop">
      <video className="video" autoPlay loop muted playsInline>
        <source src={loop4} type="video/mp4" />
        <source src={loop4} type="video/ogg" />
      </video>
    </div>
    <div className="testimonial-container">
      <TestimonialSlider>
        <Quote
          author="David Kerr"
          position="CEO, Bird & Bird"
          avatar={davidKerr}
          logo={twoBirdsLogo}
          content="We are using Fibonacci because we believe
          that the best law firm is the one that is
          most tech-enabled. To deliver on the expectations
          of our clients, we need to arm our lawyers with the
          best modern day tools that allow them to boost their
          productivity and collaborate seamlessly with our clients."
        />
      </TestimonialSlider>
    </div>
    <div className="screenshot-container -reverse">
      <div className="wrapper">
        <img src={allocateScreenshot} alt="" />
      </div>
      <div className="text">
        <img className="logo" src={allocateLogo} alt="" />
        <h1>Allocate</h1>
        <h2>Smarter matter scoping and resource allocation</h2>
        <p>
          <i>Allocate </i>
          streamlines your matter scoping and allocation process and allows you to make
          informed decisions when it comes to team allocation. Ensure the
          <strong> most </strong>
          appropriate legal resources, whether internal or
          external, are utilised each and every time.
        </p>
      </div>
    </div>
    <div className="screenshot-container">
      <div className="text">
        <img className="logo" src={blueprintLogo} alt="" />
        <h1>Blueprint</h1>
        <h2>Gain full visibility from start to finish</h2>
        <p>
          From black-hole legal services delivery to complete transparency.
          Blueprint allows you to plan every workstream, milestone and task involved
          in executing a matter through to completion. Keep everyone moving fast and
          in the same direction.
        </p>
      </div>
      <div className="wrapper">
        <img src={blueprintScreenshot} alt="" />
      </div>
    </div>
    <Integrate />
    <div className="screenshot-container -reverse">
      <div className="wrapper">
        <img src={driveScreenshot} alt="" />
      </div>
      <div className="text">
        <img className="logo" src={driveLogo} alt="" />
        <h1>Drive</h1>
        <h2>Smarter and paperless document management</h2>
        <p>
          All your documents securely stored and accessible anytime and anywhere you want
          to work. We&apos;ve also added smarts and automation technology to fast-track document
          creation and accelerate compliance and approval workflows. Best yet, it integrates
          with all the current tools you already use.
        </p>
      </div>
    </div>
    <div className="screenshot-container -last">
      <div className="text">
        <img className="logo" src={playbookLogo} alt="" />
        <h1>Playbook</h1>
        <h2>Map the project journey and eliminate the guesswork</h2>
        <p>
          Template and automate key service offerings, from M&amp;A through to complex IP litigation
          (we’ve done it all before)! Increase visibility, ensure tacit knowledge is passed down,
          and deliver high-quality project outcomes.
        </p>
      </div>
      <div className="wrapper">
        <img className="-right" src={playbookScreenshot} alt="" />
      </div>
    </div>
    <MoreFeatures />
    <div className="shaded-container -mobile">
      <FeaturesSlider />
    </div>
    <div className="specter">
      <video className="image" autoPlay loop muted playsInline>
        <source src={spectre} type="video/mp4" />
      </video>
      <div className="container">
        <div className="heading">Work moves fast.</div>
        <div className="heading">
          <span className="purple">Specter</span>
          {' '}
          moves faster.
        </div>
        <div className="content">
          <div className="text">
            <b>
              Specter is Fibonacci’s artificially intelligent
              legal project manager
            </b>
            {' '}
            to help you work
            the plan, instead of always planning
            the work. Specter is able to identify
            bottlenecks and risks, automatically
            schedule routine events, create
            reminders and follow-ups, helping
            improve operational performance and
            reduce busywork.
          </div>
          <div className="container">
            <div className="chip">
              COMING SOON
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flexible-container">
      <video className="video" autoPlay loop muted playsInline>
        <source src={loop2} type="video/mp4" />
        <source src={loop2} type="video/ogg" />
      </video>
      <div className="wrapper">
        <div className="container">
          <div className="header">An industry standard for data security and privacy</div>
          <div className="content">
            Your data is safe with us.
          </div>
          <div className="section">
            <div className="content">
              <img className="logo _mb-16" src={serverLogo} alt="" />
              <div className="_font-16 _bold _mb-8">Dedicated Servers</div>
              <div className="description _font-16">Choice of 16 geographic data centers</div>
            </div>
            <div className="content">
              <img className="logo _mb-16" src={encryptionLogo} alt="" />
              <div className="_font-16 _bold _mb-8">Encryption</div>
              <div className="description _font-16">Everything, encrypted</div>
            </div>
          </div>
          <div className="section">
            <div className="content">
              <img className="logo _mb-16" src={dataLogo} alt="" />
              <div className="_font-16 _bold _mb-8">Single tenancy solutions</div>
              <div className="description _font-16">Complete control over your Fibonacci instance and your data</div>
            </div>
            <div className="content">
              <img className="logo _mb-16" src={authenticationLogo} alt="" />
              <div className="_font-16 _bold _mb-8">Authentication</div>
              <div className="description _font-16">SSO integration and two-step authentication</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LegalSpotlight />
  </Layout>
);

export default FeaturesPage;

import React, { useState, Component, useRef } from 'react';
import Slider from 'react-slick';

import autopilotFrame from '../assets/images/features-autopilot.png';
import pulseFrame from '../assets/images/features-pilot.png';
import humanFrame from '../assets/images/features-human.png';
import chevron from '../assets/images/chevron-right.svg';
import chevronWhite from '../assets/images/chevron-white.svg';

import Autopilot from '../assets/svg/autopilot.svg';
import Pulse from '../assets/svg/pulse.svg'
import Human from '../assets/svg/human.svg'


const FeaturesSlider = () => {
  const slider = useRef(null);
  const [activeSlide, setBefore] = useState(0);
  const [activeSlide2, setAfter] = useState(0);
  const seeMore = (event) => {
    const content = event.target.previousSibling;
    const viewLess = event.target.nextSibling;
    content.classList.remove('line-clamp');
    viewLess.classList.add('-block');
    event.target.classList.remove('-block');
    event.target.classList.add('-hidden');
  };

  const seeLess = (event) => {
    const viewMore = event.target.previousSibling;
    const content = viewMore.previousSibling;
    content.classList.add('line-clamp');
    viewMore.classList.add('-block');
    event.target.classList.remove('-block');
    event.target.classList.add('-hidden');
  };

  return (
    <div className="features-slider-container">
      <div className="heading">Other interesting features</div>
      <img
        src={autopilotFrame}
        className={`frame ${activeSlide === 0 ? '' : '-hidden'}`}
        alt=""
      />
      <img
        src={pulseFrame}
        className={`frame ${activeSlide === 1 ? '' : '-hidden'}`}
        alt=""
      />
      <img
        src={humanFrame}
        className={`frame ${activeSlide === 2 ? '' : '-hidden'}`}
        alt=""
      />
      <div className="slides">
        <Slider
          arrows
          centerMode
          ref={slider}
          speed={1000}
          slidesToShow={1}
          slidesToScroll={3}
          beforeChange={(current, next) => { setBefore(next); }}
          afterChange={(current) => { setAfter(current); }}
        >
          <div className="features-slide">
            <div className="text">
              <div className="icon -autopilot">
                <Autopilot />
              </div>
              <h1>Autopilot</h1>
              <p className="subtitle">Put your business processes on autopilot</p>
              <p
                className="line-clamp"
              >
                Autopilot allows you to automate repetitive tasks and processes, monitor compliance,
                accelerate core business processes and minimise mistakes. Focus on delivering quality
                work instead of being bogged down with low value mundane tasks or continually chasing
                or waiting on approvals from other stakeholders.
              </p>
              <span
                tabIndex={0}
                className="-autopilot"
                role="button"
                data-target="autopilot"
                onClick={(e) => {
                  seeMore(e);
                }}
              >
                See more
                <img src={chevron} className="more" alt="" />
              </span>
              <span
                className="-autopilot"
                style={{ display: 'none' }}
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  seeLess(e);
                }}
              >
                See less
                <img src={chevron} className="less" alt="" />
              </span>
            </div>
          </div>
          <div className="features-slide">
            <div className="text">
              <div className="icon -pulse">
                <Pulse />
              </div>
              <h1>Pulse</h1>
              <p className="subtitle">A daily legal health-check</p>
              <p
                className="line-clamp"
              >
                Make better decisions, reduce bottlenecks, and foster teamwork.
                The best of plans will never mirror real life, but proactive action
                and dialogue boosts trusts and fosters collaboration. Stay on the front
                foot at all times from the commencement of a matter through to completion.
              </p>
              <span
                tabIndex={0}
                className="-pulse"
                role="button"
                onClick={(e) => {
                  seeMore(e);
                }}
              >
                See more
                <img src={chevron} className="more" alt="" />
              </span>
              <span
                className="-autopilot"
                style={{ display: 'none' }}
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  seeLess(e);
                }}
              >
                See less
                <img src={chevron} className="less" alt="" />
              </span>
            </div>
          </div>
          <div className="features-slide">
            <div className="text">
              <div className="icon -human">
                <Human />
              </div>
              <h1>Human</h1>
              <p className="subtitle">Effective collaboration requires human interaction</p>
              <p
                className="line-clamp"
              >
                Building strong relationships with everyone involved in a matter requires you
                to understand an intricate web of human interactions that occur internally and
                externally. Through business intelligence insights, ‘human’ maps your client
                relationships via flexible record linking functionality. Be aware and informed
                on every interaction you and your colleagues make with anyone involved in the
                matter to ensure you&apos;re always up to date and never miss a beat
              </p>
              <span
                tabIndex={0}
                className="-human"
                role="button"
                onClick={(e) => {
                  seeMore(e);
                }}
              >
                See more
                <img src={chevron} className="more" alt="" />
              </span>
              <span
                className="-autopilot"
                style={{ display: 'none' }}
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  seeLess(e);
                }}
              >
                See less
                <img src={chevron} className="less" alt="" />
              </span>
            </div>

          </div>
        </Slider>
        <div className="slider-toggle _ml-auto _mr-16 _mt-32">
            <img
              role="button"
              tabIndex={0}
              className="previous"
              src={chevronWhite}
              alt=""
              onClick={() => {
                slider.current.slickPrev();
              }}
            />
            <div className="line"></div>
            <img
              role="button"
              tabIndex={0}
              className="next"
              src={chevronWhite}
              alt=""
              onClick={() => {
                slider.current.slickNext();
              }}
            />
          </div>
      </div>
    </div>
  );
};

export default FeaturesSlider;

import React, { useState } from 'react';
import Slider from 'react-slick';

import frame from '../assets/images/frame.svg';
import autopilotFrame from '../assets/images/features-autopilot.png';
import pulseFrame from '../assets/images/features-pilot.png';
import humanFrame from '../assets/images/features-human.png';
import pulse from '../assets/images/pulse.svg';
import autopilot from '../assets/images/autopilot.svg';
import human from '../assets/images/human.svg';
import chevron from '../assets/images/chevron-right.svg';

import Autopilot from '../assets/svg/autopilot.svg';
import Pulse from '../assets/svg/pulse.svg'
import Human from '../assets/svg/human.svg'

const MoreFeatures = () => {
  const [activeTile, setTile] = useState('autopilot');
  const [isAutopilotExpanded, setIsAutopilotExpanded] = useState(false);
  const [isPulseExpanded, setIsPulseExpanded] = useState(false);
  const [isHumanExpanded, setIsHumanExpanded] = useState(false);

  const seeMore = (event) => {
    const content = event.target.previousSibling;
    const viewLess = event.target.nextSibling;
    content.classList.remove('line-clamp');
    viewLess.classList.add('-block');
    event.target.classList.remove('-block');
    event.target.classList.add('-hidden');
  };

  const seeLess = (event) => {
    const viewMore = event.target.previousSibling;
    const content = viewMore.previousSibling;
    content.classList.add('line-clamp');
    viewMore.classList.add('-block');
    event.target.classList.remove('-block');
    event.target.classList.add('-hidden');
  };

  return (
    <div className="more-features">
      <div className="container">
        <div className="heading">Features to move work forward</div>
        <img
          src={autopilotFrame}
          className={`frame ${activeTile === 'autopilot' ? '' : '-hidden'}`}
          alt=""
        />
        <img
          src={pulseFrame}
          className={`frame ${activeTile === 'pulse' ? '' : '-hidden'}`}
          alt=""
        />
        <img
          src={humanFrame}
          className={`frame ${activeTile === 'human' ? '' : '-hidden'}`}
          alt=""
        />
        <div className="features">
          <div
            className={`item ${activeTile === 'autopilot' ? '-active' : ''} ${isAutopilotExpanded ? '-expanded' : ''}`}
            tabIndex={0}
            role="button"
            onClick={() => {
              setTile('autopilot');
            }}
          >
            <div className="text">
              <div className="icon -autopilot">
                <Autopilot />
              </div>
              <h1>Autopilot</h1>
              <h2>Put your business processes on autopilot</h2>
              <p
                className="line-clamp"
              >
                Autopilot allows you to automate repetitive tasks and processes, monitor compliance,
                accelerate core business processes and minimise mistakes. Focus on delivering quality
                work instead of being bogged down with low value mundane tasks or continually chasing
                or waiting on approvals from other stakeholders.
              </p>
              <span
                tabIndex={0}
                className="-autopilot"
                role="button"
                data-target="autopilot"
                onClick={(e) => {
                  e.stopPropagation();
                  seeMore(e);
                  setIsAutopilotExpanded(true);
                }}
              >
                See more
                <img src={chevron} className="more" alt="" />
              </span>
              <span
                className="-autopilot"
                style={{ display: 'none' }}
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  e.stopPropagation();
                  seeLess(e);
                  setIsAutopilotExpanded(false);
                }}
              >
                See less
                <img src={chevron} className="less" alt="" />
              </span>
            </div>
          </div>
          <div
            className={`item ${activeTile === 'pulse' ? '-active' : ''} ${isPulseExpanded ? '-expanded' : ''}`}
            tabIndex={0}
            role="button"
            onClick={() => {
              setTile('pulse');
            }}
          >
            <div className="text">
              <div className="icon -pulse">
                <Pulse />
              </div>
              <h1>Pulse</h1>
              <h2>A daily legal health-check</h2>
              <p
                className="line-clamp"
              >
                Make better decisions, reduce bottlenecks, and foster teamwork.
                The best of plans will never mirror real life, but proactive action
                and dialogue boosts trusts and fosters collaboration. Stay on the front
                foot at all times from the commencement of a matter through to completion.
              </p>
              <span
                tabIndex={0}
                className="-pulse"
                role="button"
                onClick={(e) => {
                  e.stopPropagation();
                  seeMore(e);
                  setIsPulseExpanded(true);
                }}
              >
                See more
                <img src={chevron} className="more" alt="" />
              </span>
              <span
                className="-autopilot"
                style={{ display: 'none' }}
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  e.stopPropagation();
                  seeLess(e);
                  setIsPulseExpanded(false);
                }}
              >
                See less
                <img src={chevron} className="less" alt="" />
              </span>
            </div>
          </div>
          <div
            className={`item ${activeTile === 'human' ? '-active' : ''} ${isHumanExpanded ? '-expanded' : ''}`}
            tabIndex={0}
            role="button"
            onClick={() => {
              setTile('human');
            }}
          >
            <div className="text">
              <div className="icon -human">
                <Human />
              </div>
              <h1>Human</h1>
              <h2>Effective collaboration requires human interaction</h2>
              <p
                className="line-clamp"
              >
                Building strong relationships with everyone involved in a matter requires you
                to understand an intricate web of human interactions that occur internally and
                externally. Through business intelligence insights, ‘human’ maps your client
                relationships via flexible record linking functionality. Be aware and informed
                on every interaction you and your colleagues make with anyone involved in the
                matter to ensure you&apos;re always up to date and never miss a beat.
              </p>
              <span
                tabIndex={0}
                className="-human"
                role="button"
                onClick={(e) => {
                  e.stopPropagation();
                  seeMore(e);
                  setIsHumanExpanded(true);
                }}
              >
                See more
                <img src={chevron} className="more" alt="" />
              </span>
              <span
                className="-autopilot"
                style={{ display: 'none' }}
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  e.stopPropagation();
                  seeLess(e);
                  setIsHumanExpanded(false);
                }}
              >
                See less
                <img src={chevron} className="less" alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreFeatures;

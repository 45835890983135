import React from 'react';
import zoomLogo from '../../assets/images/Zoom.svg';
import outlookLogo from '../../assets/images/Microsoft Outlook 2019.svg';
import uploadLogo from '../../assets/images/Upload to Cloud.svg';
import slackLogo from '../../assets/images/slack.svg';
import msLogo from '../../assets/images/Microsoft Teams 2019.svg';
import zoom from '../../assets/images/zoom.png';
import ms from '../../assets/images/ms-teams.png';
import outlook from '../../assets/images/ms-outlook.png';
import docStorage from '../../assets/images/one-drive.png';
import slack from '../../assets/images/slack.png';

import Card from './Card';

export default function Integrate() {
  return (
    <div className="integrate-section">
      <div className="flexible-container">
        <div className="container">
          <div className="title">
            <h2>Integrate with your existing legal technology tool-kit</h2>
          </div>
          <div className="integate-row">
            <Card
              logo={zoomLogo}
              image={zoom}
              title="Zoom"
              description="Start or schedule meetings from Fibonacci"
            />
            <Card
              logo={msLogo}
              image={ms}
              title="Microsoft Teams"
              description="Get notified directly inside Microsoft Teams"
            />
          </div>
          <div className="integate-row">
            <Card
              logo={uploadLogo}
              image={docStorage}
              title="Google Drive, OneDrive, iManage"
              description="Sync your documents from Fibonacci to GoogleDrive, OneDrive, or iManage"
            />
          </div>
          <div className="integate-row">
            <Card
              logo={slackLogo}
              image={slack}
              title="Slack"
              description="Bring your updates, and conversations in Slack from Fibonacci"
            />
            <Card
              logo={outlookLogo}
              image={outlook}
              title="Microsoft Outlook"
              description="Create, and view tasks straight from your Outlook"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
